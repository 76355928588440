import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { get } from '../../utils/apiHandler';



export const getSongsContent = createAsyncThunk('/AdminApi/ListSong', async () => {
	try {
        const res=await get("/AdminApi/ListSong")
        console.log(res);
        const data=res.data.data.dataSave
       
       return data
    } catch (error) {
        
    }
})

export const SongsSlice = createSlice({
    name: 'Songs',
    initialState: {
        isLoading: false,
        Songs : []
    },
    reducers: {


        addNewLead: (state, action) => {
           
            state.Songs = [...getSongsContent()]
        },

        deleteLead: (state, action) => {
            let {index} = action.payload
            state.Songs.splice(index, 1)
        }
    },

    extraReducers: {
		[getSongsContent.pending]: state => {
			state.isLoading = true
		},
		[getSongsContent.fulfilled]: (state, action) => {
            console.log(action);
			state.Songs = [...action.payload]
			state.isLoading = false
		},
		[getSongsContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export const { addNewLead, deleteLead } = SongsSlice.actions

export default SongsSlice.reducer