import axios from "axios"





// const baseURL = 'http://127.0.0.1:8000/';

const apiRequest = async (method, endpoint, data) => {
    const token= localStorage.getItem("token")
// console.log(BaseUrl+endpoint);
    try {

    let config = {
      method:  method,
      maxBodyLength: Infinity,
      url:"http://admin.hibyee.com:8001/v1"+endpoint,
      data:data,
      headers: { 
        'Accept': 'application/json', 
        'Authorization': 'Bearer '+token
      }
    };
   
    let result=await axios.request(config)
    
   
    return {
      response: true,
      status: result.status,
      error: null,
      data: result.data,
    };
   
  } catch (error) {
    return {
      response: false,
      status: false,
      // error: error.response.data.errors.message,
      data: error.response,
    };
  }
};

export const get = (endpoint) => apiRequest('get', endpoint, null);
export const post = (endpoint, data) => apiRequest('post', endpoint, data);
export const put = (endpoint, data) => apiRequest('put', endpoint, data);
export const remove = (endpoint, data) => apiRequest('delete', endpoint, data);
